

































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ThingStatusTypeEnum } from '@/utils/thingStatus'

@Component
export default class Header extends Vue {
  ThingStatusTypeEnum: typeof ThingStatusTypeEnum = ThingStatusTypeEnum
  @Prop({ required: true }) readonly compositeSerial!: string

  get compositeConnected() {
    return this.$store.getters['composite/compositeConnected'](this.compositeSerial)
  }

  get compositeOpStatusType() {
    return this.$store.getters['composite/compositeOpStatus'](this.compositeSerial)
  }

  get compositeAlias() {
    return this.$store.getters['composite/compositeAlias'](this.compositeSerial)
  }

  get compositeTitle() {
    return this.compositeAlias ? this.compositeAlias : this.compositeSerial
  }
}

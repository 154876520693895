var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "header" }, [
    _c("div", { staticClass: "header__title" }, [
      _c("h2", [_vm._v(_vm._s(_vm.compositeTitle))]),
      _vm.compositeAlias
        ? _c("h3", [_vm._v(_vm._s(_vm.compositeSerial))])
        : _vm._e()
    ]),
    !_vm.compositeConnected
      ? _c("div", { staticClass: "header__notif header__notif--offline" }, [
          _vm._v(" " + _vm._s(_vm.$t("Card.Thing.Offline")) + " ")
        ])
      : _vm.compositeConnected &&
        _vm.compositeOpStatusType === _vm.ThingStatusTypeEnum.Error
      ? _c("div", { staticClass: "header__notif header__notif--error" }, [
          _vm._v(" " + _vm._s(_vm.$t("Card.Thing.Error")) + " ")
        ])
      : _vm.compositeConnected &&
        _vm.compositeOpStatusType === _vm.ThingStatusTypeEnum.Warning
      ? _c("div", { staticClass: "header__notif header__notif--warning" }, [
          _vm._v(" " + _vm._s(_vm.$t("Card.Thing.Warning")) + " ")
        ])
      : _vm.compositeConnected &&
        _vm.compositeOpStatusType === _vm.ThingStatusTypeEnum.Info
      ? _c("div", { staticClass: "header__notif header__notif--info" }, [
          _vm._v(" " + _vm._s(_vm.$t("Card.Thing.Init")) + " ")
        ])
      : _c("div", { staticClass: "header__notif header__notif--valid" }, [
          _vm._v(" " + _vm._s(_vm.$t("Card.Thing.Online")) + " ")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }